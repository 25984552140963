@import '/styles/vars';

html {
    .scroll {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        overflow: auto;
        z-index: 100;
        -webkit-overflow-scrolling: touch;
        opacity: 0;
        transition: opacity $ease;
    }

    .visible {
        opacity: 1;
    }

    .backdropShow {
        opacity: 1;
        pointer-events: all;
    }

    .modal {
        pointer-events: none;
        position: relative;
        z-index: 100;
    }

    .cell {
        width: 100vw;
        width: var(--vw100);
        height: 100vh;
        vertical-align: middle;
        display: table-cell;
        padding: $gap48;
        @include mobile {
            // padding: $gap30 $gap12;
            padding: 80px $gap12;
        }
    }

    .message,
    .regular {
        position: relative;
        pointer-events: all;
        width: 100%;
        background: #fff;
        margin: 0 auto;
        padding: $gap48 $gap48;
        border-radius: $radius2;
        overflow: hidden;
    }

    .message {
        max-width: 500px;
        text-align: center;
    }

    .close {
        color: #fff;
        position: fixed;
        right: $gap18;
        top: $gap18;
        pointer-events: all;
        cursor: pointer;
        &:hover {
            color: $color_accent;
        }
    }

    .text {
        font-size: $font24;
        color: $color_dark;
        margin-bottom: $gap36;
    }

    .content {
        pointer-events: all;
        width: 600px;
        max-width: 600px;
        margin: 0 auto;
        background: #fff;
        padding: $gap48;
        border-radius: $radius3;
        text-align: center;
        @include mobile {
            padding: $gap36 $gap24;
            width: auto;
        }
    }
}
