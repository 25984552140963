@mixin headerBackground() {
    &:before {
        @include blur;
        // box-shadow: $shadow;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        content: '';
    }
    // &:after {
    //     content: '';
    //     position: absolute;
    //     top: 100%;
    //     left: 0;
    //     width: 100%;
    //     border-bottom: $transparent_border;
    // }
}

@mixin scroll() {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

@mixin blur() {
    // backdrop-filter: blur(4px);
    background: $color_light; //fade-out($color_light, 0.03);
}

@mixin mobile() {
    @media only screen and (max-width: $maxMobile) {
        @content;
    }
}

@mixin tablet() {
    @media only screen and (max-width: $maxTablet) {
        @content;
    }
}

@mixin middle() {
    @media only screen and (max-width: 1079px) {
        @content;
    }
}

@mixin desktop() {
    @media only screen and (min-width: $minDesktop) {
        @content;
    }
}
