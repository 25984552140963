@import '/styles/vars';

.backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: $color_modal_bg;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: opacity $ease;
    z-index: 19;
}
