@import 'styles/mixins';

// fonts
// $bodyFont: 'Jost', sans-serif;
// $bodyFont: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
$bodyFont: 'Nunito Sans', sans-serif;
$headerFont: 'Besley', serif;

// colors
$color_dark: #232f2a;
$color_medium: #232f2a; //rgba(35, 47, 42, 0.7); //#232f2a;
$color_faded: #afb2b1;
$color_yellow: #f4ba60;
$color_red: #c95355;
$color_green: #60b17f;
$color_light: #f4f4f2; //#f2f4f4;
$color_green_dark: #232f2a;
$color_border: #c4cdc9; //#c5c7c5; //#d9dedc; //#c4cdc9;
$color_accent: $color_yellow;
$color_modal_bg: rgba(35, 47, 42, 0.7);
$color_bg: #fff;
$color_white: rgba(255, 255, 255, 0.95);
$color_green_text: $color_green;
$color_red_text: $color_red;

// colors - hover

$hover_color_light: darken($color_light, 4%);
$hover_color_accent: darken($color_accent, 4%);
$hover_color_red: darken($color_red, 4%);
$hover_color_green: darken($color_green, 4%);
$hover_color_border: darken($color_border, 10%);

// colors - light

$color_yellow_light: rgba(255, 193, 35, 0.12);
$color_red_light: rgba(255, 76, 0, 0.1);
$color_pink_light: rgba(255, 102, 160, 0.12);
$color_blue_light: rgba(41, 117, 255, 0.12);
$color_green_light: rgba(0, 204, 150, 0.12);
$color_green_light_hover: rgba(0, 204, 150, 0.2);

// font weight

$normal: 400;
$medium: 500;
$bold: 500;
$boldHeading: 600;

// font sizes

$font13: 14px;
$font14: 16px;
$font16: 17px;
$font18: 18px;
$font24: 24px;
$font30: 30px;
$font36: 36px;
$font48: 48px;
$font60: 60px;
$fontm: 15px;

// border radius

// $radius1: 6px;
// $radius2: 9px;
// $radius3: 9px;
// $radius1: 3px; //6px;
// $radius2: 3px; //6px;
// $radius3: 3px; //6px;
// $radiusHero: 9px;
// $radiusImg: 3px;
$radius1: 6px;
$radius2: 6px;
$radius3: 6px;
$radiusHero: 9px;
$radiusImg: 6px;

// sizes

$gap12: 12px;
$gap18: 18px;
$gap30: 30px;
$gap24: 24px;
$gap36: 36px;
$gap42: 42px;
$gap48: 48px;
$gap60: 60px;
$gap72: 72px;
$gapM: 12px;

// other

$wrap: 1280px + $gap48 + $gap48 - 8px;
$border: 1px solid $color_border;
$dashed: 1px dashed $color_border;
$transparent_border: 1px solid rgba(21, 55, 56, 0.13);
$ease: 300ms ease-in-out;
$shadow: 0 0 60px 0 rgba(21, 55, 56, 0.04);
$shadow2: 0 0 60px 0 rgba(21, 55, 56, 0.08);
$maxMobile: 749px;
$maxTablet: 899px;
$minDesktop: 899px;
$headerDesktopMargin: 60px;
$headerMobileMargin: 36px;
